import request from '@/api/request'

//PC工作通-工作通列表
export function workList(data) {
    return request({
        url: '/web/working/List',
        method: 'GET',
        params: data
    })
}

//PC工作通-企业列表
export function companyList(data) {
    return request({
        url: '/web/working/company/List',
        method: 'GET',
        params: data
    })
}


//PC工作通-人员列表
export function enrollList(data) {
    return request({
        url: '/web/working/enroll/List',
        method: 'GET',
        params: data
    })
}

//PC工作通-人员详情
export function enrollInfo(data) {
    return request({
        url: '/web/working/enroll/info',
        method: 'GET',
        params: data
    })
}


export function enrollUpdate(data) {
    return request({
        url: '/web/working/enroll/update',
        method: 'POST',
         data
    })
}




//PC工作台-设置打卡列表
export function clockEmplaceList(data) {
    return request({
        url: '/web/clock/emplace/list',
        method: 'GET',
        params: data
    })
}


//PC工作台-审批详情
export function leaveInfo(data) {
    return request({
        url: '/web/leave/info',
        method: 'GET',
        params: data
    })
}


export function leaveList(data) {
    return request({
        url: '/web/leave/list',
        method: 'GET',
        params: data
    })
}


//PC工作台-审批审核
export function leaveExamine(data) {
    return request({
        url: '/web/leave/examine',
        method: 'POST',
         data
    })
}


//PC工作台-审批设置删除
export function leaveEmplaceDel(data) {
    return request({
        url: '/web/leave/emplaceDel',
        method: 'POST',
        data
    })
}

//PC工作台-设置战报列表
export function reportEmplaceList(data) {
    return request({
        url: '/web/report/emplace/list',
        method: 'GET',
        params: data
    })
}

//PC工作台-设置战报详情
export function reportEmplaceInfo(data) {
    return request({
        url: '/web/report/emplace/info',
        method: 'GET',
        params: data
    })
}

//PC工作台-设置战报
export function reportEmplace(data) {
    return request({
        url: '/web/report/emplace',
        method: 'POST',
        data
    })
}




//PC工作台-战报列表
export function reportList(data) {
    return request({
        url: '/web/report/list',
        method: 'GET',
        params: data
    })
}


//PC工作台-战报详情
export function reportInfo(data) {
    return request({
        url: '/web/report/info',
        method: 'GET',
        params: data
    })
}





//PC工作台-战报评价
export function reportAssess(data) {
    return request({
        url: '/web/report/assess',
        method: 'POST',
        data
    })
}










///打卡日报
export function clockDayList(data) {
    return request({
        url: '/web/clock/day/forms',
        method: 'GET',
        params: data
    })
}
//打卡月报
export function clockMonthList(data) {
    return request({
        url: '/web/clock/month/forms',
        method: 'GET',
        params: data
    })
}

//PC工作台-职位下的人员列表
export function clockUserList(data) {
    return request({
        url: '/web/clock/user/list',
        method: 'GET',
        params: data
    })
}



//PC工作台-打卡日月报汇总
export function clockMonthInfo(data) {
    return request({
        url: '/web/clock/month/info',
        method: 'POST',
        data
    })
}


//PC工作台-打卡日月报人员详情
export function clockMonthUserInfo(data) {
    return request({
        url: '/web/clock/month/userInfo',
        method: 'GET',
        params: data
    })
}

//PC工作台-打卡日月报单人详情
export function clockMonthUserShow(data) {
    return request({
        url: '/web/clock/month/usershow',
        method: 'GET',
        params: data
    })
}

export function clockMonthCalendar(data) {
    return request({
        url: '/web/clock/month/calendar',
        method: 'GET',
        params: data
    })
}
