import request from '../request'


//人才库列表
export function getClass(data) {
    return request({
        url: 'company/talent/getClass',
        method: 'GET',
        params: data
    })
}

//查询分类下的用户数据
export function getTalent(data) {
    return request({
        url: 'company/talent/getTalent',
        method: 'GET',
        params: data
    })
}

//添加分类
export function postClass(data) {
    return request({
        url: 'company/talent/postClass',
        method: 'POST',
        data
    })
}

//添加人才
export function addTalent(data) {
    return request({
        url: 'company/talent/addTalent',
        method: 'POST',
        data
    })
}
