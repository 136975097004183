<template>
  <div class="container">
    <el-row style="margin:20px 0px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>人才库</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-container style="border: 1px solid #eee">
      <el-aside width="200px" style="min-height:500px;border: 1px solid #eee">
        <div class="head-container catefilter">
          <el-input
              v-model="filterCate"
              placeholder="请输入分类名称"
              clearable
              size="small"
          />
          <el-dropdown slot="append" trigger="hover" @command="handleCommand">
            <el-button type="text" icon="el-icon-plus" style="padding:10px"></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">添加分类</el-dropdown-item>
              <el-dropdown-item command="2">修改分类</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="head-container">
          <p class="all active" @click="allUser">所有人才</p>
          <el-tree
              :data="cateList"
              :props="defaultProps"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              ref="tree"
              @node-click="handleNodeClick"
          />
        </div>
      </el-aside>
      <!--用户数据-->
      <el-main v-show="mainStatus==0">
        <div style="margin-bottom: 20px;">
          <span style="font-size: 18px;color: #666;">{{ this.className }}</span>
          <span style="font-size: 12px;color: #666;">（{{ this.pageProps.total }}人）</span>
        </div>
        <el-form :model="searchFormData" ref="queryForm" :inline="true" label-width="68px">
          <el-form-item label="用户名称" prop="alias_name">
            <el-input
                v-model="searchFormData.alias_name"
                placeholder="请输入用户名称"
                clearable
                size="small"
                style="width: 240px"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>
        <el-row class="mb8" style="text-align:right;background-color: #dce5f1;padding: 10px;">
          <el-col :span="6" :offset="18">
            <el-button
                plain
                size="mini"
                @click="addUser"
            >新增
            </el-button>
            <el-button
                plain
                size="mini"
                @click="batchUser"
            >批量导入
            </el-button>
          </el-col>
        </el-row>
        <el-table
            style="min-height:500px;"
            @selection-change="handleSelectionChange"
            ref="table"
            :data="userList"
            element-loading-text="正在查询"
            fit
            stripe
            highlight-current-row>
          <el-table-column type="selection" width="50" align="center"/>
          <el-table-column align="center" label="姓名" prop="alias_name"></el-table-column>
          <el-table-column align="center" label="性别" prop="sex_type"></el-table-column>
          <el-table-column align="center" label="手机号" prop="mobile"></el-table-column>
          <el-table-column align="center" label="昵称" prop="nickname"></el-table-column>
          <el-table-column align="center" label="备注" prop="remarks"></el-table-column>
          <el-table-column align="center" label="添加时间" prop="create_at"></el-table-column>
          <el-table-column
              label="操作"
              align="center"
              width="160"
              class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <!-- 如果 user_status 的值为 2，则显示下面的按钮 -->
<!--              <el-button-->
<!--                  v-if="scope.row.user_status==2"-->
<!--                  size="mini"-->
<!--                  type="text"-->
<!--                  icon="el-icon-edit"-->
<!--                  @click="detailInfo(scope.row.talent_id)"-->
<!--              >详情-->
<!--              </el-button>-->
              <span v-if="scope.row.user_status==1">{{ scope.row.user_status_name }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align:center;padding:20px">
          <!--分页-->
          <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageProps.total"
              :page-size="pageProps.pageSize"
              :page-sizes="[5, 10, 50, 100]"
              :current-page="pageProps.pageNum"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
      <!--修改添加分组-->
      <el-main v-show="mainStatus==1||mainStatus==2">
        <el-form ref="form2" label-width="100px" :rules="addClassRules" :model="addCateForm">
          <el-form-item label="父级分类" prop="pid">
            <treeselect
                v-model="addCateForm.pid"
                :options="menuOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="选择上级分类"
            />
          </el-form-item>
          <el-form-item label="分类名称 " prop="class_name">
            <el-input type="text" v-model="addCateForm.class_name"></el-input>
          </el-form-item>
          <el-form-item label="备注 " prop="remarks">
            <el-input type="text" v-model="addCateForm.remarks"></el-input>
          </el-form-item>
          <el-form-item v-if="addCateForm.regular==1 || mainStatus==1">
            <el-button @click="back">取消</el-button>
            <el-button type="primary" @click="submitClass">提交</el-button>
          </el-form-item>
        </el-form>
      </el-main>
      <el-main v-show="mainStatus==3">
        <el-form ref="form3" label-width="100px" :rules="userRules" :model="addUserFormData">
          <el-form-item label="分类" prop="pid">
            <treeselect
                v-model="addUserFormData.class_id"
                :options="menuOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="选择分类"
            />
          </el-form-item>
          <el-form-item label="用户名" prop="alias_name">
            <el-input type="text" v-model="addUserFormData.alias_name"></el-input>
          </el-form-item>
          <el-form-item label="电话 " prop="mobile">
            <el-input type="text" v-model="addUserFormData.mobile"></el-input>
          </el-form-item>
          <el-form-item label="身份" prop="user_type">
            <el-radio-group v-model="addUserFormData.user_type">
              <el-radio :label="1">求职</el-radio>
              <el-radio :label="2">主管</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="addUserFormData.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
              <el-radio :label="0">保密</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注 " prop="remarks">
            <el-input type="text" v-model="addUserFormData.remarks"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="back">取消</el-button>
            <el-button type="primary" @click="submitUser">提交</el-button>
          </el-form-item>
        </el-form>
      </el-main>
      <!--添加人员-->
      <el-main v-show="mainStatus==4">
        <el-form ref="form4" :model="batchFormData" label-width="100px">
          <el-form-item label="父级分类" prop="class_id">
            <treeselect
                v-model="batchFormData.class_id"
                :options="menuOptions"
                :normalizer="normalizer"
                :show-count="true"
                disabled="disabled"
                placeholder="选择上级分类"
            />
          </el-form-item>
          <el-form-item label="" prop="">
            <el-button @click="downloadTpl">人才库模板下载</el-button>
          </el-form-item>
          <el-form-item label="" prop="">
            <excelUpload v-model="batchFormData.list"/>
          </el-form-item>
          <el-form-item>
            <el-button @click="back">取消</el-button>
            <el-button type="primary" @click="submitBatch">确定</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
    <info-dialog ref="infoDialogRef"></info-dialog>
  </div>
</template>
<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import urlConfig from '@/api/config'
import infoDialog from "@/views/talentdb/info-dialog.vue";
import Treeselect from "@riophae/vue-treeselect";
import ExcelUpload from "@/components/ExcelUpload/index.vue";
import {v2Query} from "@/api/common";
import {getClass, getTalent, postClass, addTalent} from "@/api/talentdb/index.js";

export default {
  name: "User",
  components: {
    infoDialog,
    Treeselect,
    ExcelUpload
  },
  data() {
    return {
      batchFormData: {
        class: 0,
        list: [],
      },
      className: "共计",
      mainStatus: 0,
      type: 1,
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      userList: [],
      // 非多个禁用
      multiple: true,
      filterCate: "",
      cateList: [],
      selectedClass: null,
      menuOptions: [],
      defaultProps: {
        children: "childs",
        label: "class_name"
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      searchFormData: {
        project_id: "",
        user_type: "",
        type: 2,//
        alias_name: "",
        page: "",
        limit: "",
        class_id: ""
      },
      pageProps: {
        records: [],
        total: 0,
        pageSize: 10,
        pageNum: 1,
        class_id: ""
      },
      addCateForm: {
        class_id: '',
        pid: 0,
        class_name: '',
        remarks: ''
      },
      addUserFormData: {
        alias_name: '',
        mobile: '',
        class_id: '',
        remarks: "人才库添加",
        sex: 1,
        user_type: 1,
        list: [],
      },
      addClassRules: {
        class_name: [
          {required: true, message: '请填写分类名称', trigger: 'blur'}
        ],
      },
      userRules: {
        class_id: [
          {required: true, message: '请选择分组', trigger: 'blur'}
        ],
        alias_name: [
          {required: true, message: '请填写名称', trigger: 'blur'}
        ],
        mobile: [
          {required: true, message: '请填写电话', trigger: 'blur'},
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对",
            trigger: "change",
          },
        ],
      }
    };
  },
  watch: {
    filterCate(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.getClass();
    this.getUserList();
  },
  methods: {
    downloadTpl: function () {
      var _url = urlConfig.prod.baseURL + '/apiv2/excel-talent-tpl';
      window.location.href = _url;
    },
    //分类
    getClass() {
      var that = this;
      getClass().then((res) => {
        if (res.code == 200) {
          that.cateList = res.data;
          that.classTransf();
        } else {
          this.$message.error(res.msg ? res.msg : "请求错误");
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      console.log(data)
      var that = this;
      that.pageProps.pageNum = 1;
      that.className = data.class_name;
      that.mainStatus = 0;
      that.userList = [];
      that.searchFormData.class_id = data.class_id;
      that.searchFormData.type = 1;
      this.selectedClass = data;
      that.getUserList();
    },
    handleCommand(command) {
      var that = this;
      if (command == 1) {//添加分组
        this.mainStatus = 1;
        this.selectedClass = null
        this.addCateForm = {
          class_id: '',
          pid: 0,
          class_name: '',
          remarks: ''
        }
      } else {//修改名称
        if (!that.searchFormData.class_id) {
          this.$message.warning('请选择分类');
          return;
        } else {
          this.mainStatus = 2;
          this.addCateForm = this.selectedClass;
        }
      }
    },
    allUser(key) {
      if (key == 2) {
        this.company();
        return false;
      }
      this.searchFormData.type = 2;
      this.className = "共计";
      this.getUserList();
    },
    company() {
      var that = this;
      getCompanyUser(that.searchFormData).then((res) => {
        if (res.code == 200) {
          that.userList = res.data.data;
          that.pageProps.total = res.data.total ?? 0;
        } else {
          this.$message.error(res.msg ? res.msg : "请求错误");
        }
      });
    },
    community() {
    },
    promotion() {
    },
    getUserList() {
      var that = this;
      that.searchFormData.page = that.pageProps.pageNum;
      that.searchFormData.list_rows = that.pageProps.pageSize;
      getTalent(that.searchFormData).then((res) => {
        if (res.code == 200) {
          that.userList = res.data.data;
          that.pageProps.total = res.data.total ?? 0;
        } else {
          this.$message.error(res.msg ? res.msg : "请求错误");
        }
      });
    },
    // 当前页变更
    handleCurrentChange(num) {
      this.pageProps.pageNum = num
      this.getUserList()
    },
    // 每页显示页面数变更
    handleSizeChange(size) {
      this.pageProps.pageSize = size
      this.getUserList()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getUserList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.searchFormData.alias_name = "";
      this.getUserList()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.userId);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    detailInfo(tealent_id) {
      this.$refs.infoDialogRef.init(tealent_id);
    },
    //添加分类数据
    submitClass() {
      var that = this;
      this.$refs["form2"].validate((valid) => {
        if (valid) {
          postClass(that.addCateForm).then((res) => {
            if (res.code == 200) {
              that.getClass();
              that.mainStatus = 0;
              //that.cateList = res.data;
              //  that.classTransf();
              that.$message.success(res.msg ? res.msg : "请求成功");
            } else {
              that.$message.error(res.msg ? res.msg : "请求错误");
            }
          });
        }
      });
    },
    submitBatch() {
      var that = this;
      v2Query({
        batch_talent: {
          form: that.batchFormData,
        }
      }).then((res) => {
        if (res.code == 200) {
          that.batchFormData.list = [];
          that.mainStatus = 0;
          that.$message.success(res.msg ? res.msg : "请求成功");
          that.getClass();
          that.getUserList();
        } else {
          that.$message.error(res.msg ? res.msg : "请求错误");
        }
      });
    },
    //套上一个根目录
    classTransf() {
      this.menuOptions = [];
      const menu = {class_id: 0, class_name: '全部', childs: []};
      menu.childs = this.cateList;
      this.menuOptions.push(menu);
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.childs && !node.childs.length) {
        delete node.childs;
      }
      return {
        id: node.class_id,
        label: node.class_name,
        children: node.childs
      };
    },
    addUser() {
      this.addUserFormData = {
        alias_name: '',
        mobile: '',
        class_id: 0,
        remarks: "人才库添加",
        sex: 1,
        user_type: 1,
      };
      this.addUserFormData.class_id = this.selectedClass ? this.selectedClass.class_id : 0;
      this.mainStatus = 3;
    },
    batchUser() {
      this.batchFormData = {
        class_id: 0,
        list: [],
      };
      this.batchFormData.class_id = this.selectedClass ? this.selectedClass.class_id : 0;
      this.mainStatus = 4;
      this.batchFormData.list = [];
    },
    //添加用户
    submitUser() {
      this.$refs['form3'].validate((valid) => {
        if (valid) {
          addTalent(this.addUserFormData).then((res) => {
            if (res.code == 200) {
              this.getUserList()
              // that.cateList = res.data;
              //  that.classTransf();
              this.$message.success(res.msg ? res.msg : "请求成功");
              this.mainStatus = 0;
            } else {
              this.$message.error(res.msg ? res.msg : "请求错误");
            }
          });
        }
      });
    },
    back() {
      this.mainStatus = 0;
    }
  }
};
</script>
<style lang="scss" scoped>
.catefilter {
  margin: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-input--small .el-input__inner {
    border: unset !important;
  }
}

.all {
  font-size: 14px;
  color: #666666;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background: rgb(220, 229, 241);
  }

  &.active {
    background: rgb(220, 229, 241);
  }
}
</style>
