<template>
  <div class="upload-file">
    <el-upload
        :action="uploadFileUrl"
        :before-upload="handleBeforeUpload"
        :limit="1"
        :on-error="handleUploadError"
        :on-exceed="handleExceed"
        :on-success="handleUploadSuccess"
        :show-file-list="false"
        class="upload-file-uploader"
        ref="upload"
    >
      <!-- 上传按钮 -->
      <el-button size="mini" type="primary">上传文件</el-button>
      <!-- 上传提示 -->
      <div class="el-upload__tip" slot="tip" v-if="showTip">
        请上传
        <template v-if="fileSize">大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b></template>
        <template v-if="fileType"> 格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b></template>
        的文件
      </div>

    </el-upload>
    <el-button size="mini" type="default" @click="resetData">重置</el-button>
    <!-- 文件列表 -->
    <el-table
        style="min-height:500px;"
        ref="table"
        :data="list"
        element-loading-text="正在查询"
        fit
        stripe
        highlight-current-row>
      <el-table-column align="center" label="姓名" prop="alias_name"></el-table-column>
      <el-table-column align="center" label="手机号" prop="mobile"></el-table-column>
      <el-table-column align="center" label="性别" prop="sex"></el-table-column>
      <el-table-column align="center" label="身份" prop="user_type"></el-table-column>
      <el-table-column align="center" label="备注" prop="remarks"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import urlConfig from '@/api/config'

export default {
  props: {
    // 值
    value: [String, Object, Array],
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 50,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => [],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      FileHost: "",
      uploadFileUrl: urlConfig.prod.baseURL + "/web/file/excel-upload", // 上传的图片服务器地址
      list: [],
    };
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },
  mounted() {
    this.$emit("input", []);
    this.list = [];
  },
  methods: {
    resetData() {
      this.list = [];
      this.$refs.upload.clearFiles();

      this.$emit("input", '');
      // 如果有其他需要重置的数据，也可以在这里添加
    },
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      var that = this;
      that.list = [];
      // 校检文件类型
      if (this.fileType) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        // if (!isTypeOk) {
        //   this.$message.error(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
        //   return false;
        // }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`只允许上传单个文件`);
    },
    // 上传失败
    handleUploadError(err) {
      this.$message.error("上传失败, 请重试");
    },
    // 上传成功回调
    handleUploadSuccess(res, file) {
      this.list = res.data;
      this.$message.success("上传成功");
      this.$emit("input", this.list);

    },
    // 获取文件名称
    getFileName(name) {
      if (name.lastIndexOf("/") > -1) {
        return name.slice(name.lastIndexOf("/") + 1).toLowerCase();
      } else {
        return "";
      }
    }
  },
  created() {
    this.list=[];
  },
};
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}

.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}

.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}

.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}
</style>
