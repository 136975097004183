<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="人员详情"
    width="500px"

  >
    <div>
      <el-form ref="form" :model="formData"  :rules="rules" label-width="100px">


        <el-form-item label="昵称	" prop="nickname" >
          {{ formData.nickname }}
        </el-form-item>

        <el-form-item label="电话	" prop="mobile" >
          {{ formData.mobile }}
        </el-form-item>

        <el-form-item label="头像	" prop="avatar" >
          <el-image
            style="width: 100px; height: 100px"
            :src="formData.avatar"
            fit="fit"></el-image>
        </el-form-item>
        <el-form-item label="状态	" prop="relation_status" >
          <el-radio-group v-model="formData.relation_status">
            <el-radio :label="2">淘汰</el-radio>
            <el-radio :label="3" >备选</el-radio>
            <el-radio :label="6">通过</el-radio>
            <el-radio :label="14">入职</el-radio>

          </el-radio-group>
      </el-form-item>


      <el-form-item label="地址" prop="j_address_id" >
         <el-select v-model="formData.j_address_id" placeholder="请选择">

           <el-option
            v-for="item in formData.address"
              :key="item.j_address_id"
              :label="item.address"
              :value="item.j_address_id">
          </el-option>

         </el-select>
       </el-form-item>


        <!--要修改的状态14入职，2淘汰，3备选
-->

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmit" >修改</el-button>
      <el-button @click="visible = false">取 消</el-button>
    </span>


  </el-dialog>
</template>

<script>
import { enrollInfo,enrollUpdate} from "@/api/workmaster/index";

export default {

  data() {
    return {
      visible: false,

      formData: {
        enroll_id:"",
        nickname:"",
        avatar: "",
        mobile: "",
        relation_status: "",
        j_address_id:"",
        address:[]

      },

      rules: {

        relation_status: [
             {required: true, message: '请选择状态', trigger: 'blur'}
         ],
         j_address_id: [
           {required: true, message: '请选择地址', trigger: 'blur'}
       ],


   }

    };
  },


  mounted() {


},

  methods: {
    handleClose() {
      this.visible = false;
      this.$refs["form"].resetFields();

    },


    init(enroll_id) {
      this.formData.enroll_id=enroll_id;
      this.initData();
      this.visible = true;
    },

    initData(){
      var that=this;
      enrollInfo({"enroll_id":that.formData.enroll_id}).then((response) => {
        console.log(response);
        if(response.code==200){
          that.formData=response.data
        }else{
          this.$message({
            message: response.msg?response.msg:"获取信息失败",
          });
        }
      });
    },



    onSubmit(){
      var that=this;
      var params={
        user_app_id:that.formData.user_app_id,
        enroll_id:that.formData.enroll_id,
        relation_status: that.formData.relation_status,
        j_address_id:that.formData.j_address_id,
      }
      enrollUpdate(params).then((response) => {
        console.log(response);
        if(response.code==200){
          this.$message({
              message: "提交成功",
              type: 'success'

          });
          that.$emit('refresh');
          that.visible = false;

        }else{
          this.$message({
            message: response.msg?response.msg:"提交失败",
          });
        }
      });

    },



  },


};
</script>

<style lang="scss" scoped>



/deep/ .myWindow {
    border-radius: 15px;



.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>
